import React, { useRef, useState, useContext, useEffect } from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { useLocation } from '@reach/router';
import Loader from "react-loader-spinner";
import { FinanceCalculatorContext } from '../financing/storage/CalculatorContext';
import useRenderizable from '../hooks/useVehicleContextValidator';
import useDataLayer from '../../../hooks/useDataLayer';
import mfc from '../modules/modalFormClient';
import { captureEvent } from 'event-service/modules/capture'
import "../../contact/ContactForm.scss"
import grid from "../../../scss/flexboxgrid.module.scss"
import view from './enquiryModal.module.scss';
import nextArrow from './nextArrow.png';
import nextArrowHover from './nextArrowHover.png';
// import view from "./form.module.scss"
const staticQuery = graphql`
    query {
    AllInventory {
      MainPhotoUrl
      VIN
      Pricing {
        ExtraPrice1
        List
      }
    }
    siteData {
    siteData {
      siteMetadata {
        dealers {
          DealerName
        }
      }
    }
    }
  }
`;
function EnquiryModal(props) {
  const [context, setContext] = useContext(FinanceCalculatorContext)
  const { finalPrice, vehicleInfo } = props.contextualized ? context : props
  const [isModelPage, setIsModelPage] = useState(false);
  const [isRangePage, setIsRangePage] = useState(false);
  const { siteID, leadURL, disclaimerContact, gtmId } = props
  const [modelTitle, setModelTitle] = useState(null);
  const [modelSubtitle, setModelSubtitle] = useState(null);
  const [jellybeanImage, setJellybeanImage] = useState('/jellybeans/Aston-Martin-Logo.png');

  // Static content for model pages
  const fallbackTitle = "Aston Martin Vehicles";
  const fallbackSubtitle = dealerName;
  const data = useStaticQuery(staticQuery);
  const { AllInventory } = data;
  
  // Find matching inventory based on VIN
  const matchingInventory = AllInventory?.find(inventory => inventory.VIN === vehicleInfo.VIN);
  const vehiclePricing = AllInventory?.find(inventory => inventory.VIN === vehicleInfo.VIN)?.Pricing;

  const vehiclePrice = vehiclePricing 
  ? (vehiclePricing.ExtraPrice1 > 0 ? vehiclePricing.ExtraPrice1 : vehiclePricing.List || '0')
  : '0';

  useEffect(() => {
    const modalHeadElements = document.querySelectorAll('[class*="modal-module--modal-head"]');
    const modalWrapElements = document.querySelectorAll('[class*="modal-module--modal-wrap"]');
    
    if (isRenderizable) {
      // Add class to each modal head element when the modal is visible
      modalHeadElements.forEach(modalHeadElement => {
        modalHeadElement.classList.add(view['enquiry-modal-visible']);
      });
  
      // Add class to each modal wrap element when the modal is visible
      modalWrapElements.forEach(modalWrapElement => {
        modalWrapElement.classList.add(view['enquiry-modal-wrap']);
      });
    }
  
    return () => {
      // Clean up: remove the class from all modal elements when the component unmounts
      modalHeadElements.forEach(modalHeadElement => {
        modalHeadElement.classList.remove(view['enquiry-modal-visible']);
      });
      modalWrapElements.forEach(modalWrapElement => {
        modalWrapElement.classList.remove('enquiry-modal-wrap');
      });
    };
  }, [isRenderizable]); // Only run when isRenderizable changes
        
    useEffect(() => {
    if (typeof window !== 'undefined') {
      // Delay to ensure DOM is fully loaded
      setTimeout(() => {
        const modelPageWrapper = document.querySelector('.model-page-wrapper');
        if (modelPageWrapper) {
          // Set isModelPage to true since the wrapper is found
          setIsModelPage(true);

          const h1Element = modelPageWrapper.querySelector('h1');
          const h3Element = modelPageWrapper.querySelector('h3');
  
          const title = h1Element?.innerText || "Default Model Title";
          const subtitle = h3Element?.innerText || "Default Model Subtitle";
  
          // Set modelTitle and modelSubtitle
          setModelTitle(title);
          setModelSubtitle(subtitle);
  
          const formattedTitle = title.replace(/\s+/g, '').toLowerCase(); // Remove spaces and lowercase
  
          // Paths for the images
          const webpImagePath = `/jellybeans/${formattedTitle}.webp`;
          const pngImagePath = `/jellybeans/${formattedTitle}.png`;
          const defaultImagePath = `/jellybeans/Aston-Martin-Logo.png`; // Default fallback image
  
          // Try to load the webp image first, then png, and finally fallback to the default image
          const img = new Image();
          
          img.onerror = () => {
            const pngImg = new Image();
            pngImg.onerror = () => setJellybeanImage(defaultImagePath);
            pngImg.onload = () => setJellybeanImage(pngImagePath);
            pngImg.src = pngImagePath;
          };
  
          img.onload = () => setJellybeanImage(webpImagePath);
          img.src = webpImagePath;
        }
      }, 0);
    }
  }, []);
    
  useEffect(() => {
    if (typeof window !== 'undefined') {
      // This function extracts the model from the URL query parameters
      const getModelFromURL = () => {
        const params = new URLSearchParams(window.location.search);
        return params.get('model');  // Extracts the value of 'model'
      };
  
      // Delay to ensure DOM is fully loaded
      setTimeout(() => {
        const modelParam = getModelFromURL();  // Get the model identifier from the URL
  
        if (modelParam) {
          // Find the corresponding .amb100ModelInfo using the modelParam
          const modelInfo = document.querySelector(`.amb100ModelInfo.model${modelParam}`);
  
          if (modelInfo) {
            const modelLabel = modelInfo.querySelector('.amb100ModelLabel');
            const h1Element = modelLabel.querySelector('h1');
            const h3Element = modelLabel.querySelector('h3');
  
            const title = h1Element?.innerText || "Default Model Title";
            const subtitle = h3Element?.innerText || "Default Model Subtitle";
  
            setModelTitle(title);
            setModelSubtitle(subtitle);
  
            const formattedTitle = title.replace(/\s+/g, '').toLowerCase();
  
            // Image paths for the model
            const webpImagePath = `/jellybeans/${formattedTitle}.webp`;
            const pngImagePath = `/jellybeans/${formattedTitle}.png`;
            const defaultImagePath = `/jellybeans/Aston-Martin-Logo.png`;
  
            // Try to load the webp image first, then png, and fallback to default
            const img = new Image();
  
            img.onerror = () => {
              const pngImg = new Image();
              pngImg.onerror = () => setJellybeanImage(defaultImagePath);
              pngImg.onload = () => setJellybeanImage(pngImagePath);
              pngImg.src = pngImagePath;
            };
  
            img.onload = () => setJellybeanImage(webpImagePath);
            img.src = webpImagePath;
  
            // Set isRangePage to true to indicate we're on a range page
            setIsRangePage(true);
          }
        }
      }, 0);
    }
  }, []);  
    
  useEffect(() => {
    if (matchingInventory && matchingInventory.MainPhotoUrl && !isModelPage) {
      // Update the context to include MainPhotoUrl
      setContext(prevContext => ({
        ...prevContext,
        mainPhotoUrl: matchingInventory.MainPhotoUrl
      }));
    }
  }, [matchingInventory, isModelPage, setContext]);

  // Adding step tracking state
  const [currentStep, setCurrentStep] = useState(1); 
  const [enquiryType, setEnquiryType] = useState(''); // Tracking inquiry type
  
  // Function to update the current step and inquiry type
  const handleEnquiryTypeSelection = (type, buttonRef) => {
    // Set the enquiryType and overwrite any previous selection
    setEnquiryType(type);
  
    const vehicleDetails = vehicleInfo.Year && !isModelPage ? `${vehicleInfo.Year} ${vehicleInfo.Make} ${vehicleInfo.Model}` : modelTitle ? modelTitle : 'Aston Martin Models';
  
    setFields(prevFields => ({
      ...prevFields,
      enquiryType: type,  
      comments: `Inquiry Type: ${type}\nVehicle: ${vehicleDetails}` 
    }));
  
    // Ensure this only runs in the browser
    if (typeof window !== 'undefined') {
      // Add active class to the selected button and remove from others in view
      const reasonButtons = document.querySelectorAll(`.${view.reasonButton}`);
      reasonButtons.forEach(button => {
        button.classList.remove(view.active);
      });
  
      if (buttonRef.current && buttonRef.current.classList.contains(view.reasonButton)) {
        buttonRef.current.classList.add(view.active);
      }
    }
  };
    
  const saleButtonRef = useRef(null);
  const testDriveButtonRef = useRef(null);
  const generalButtonRef = useRef(null);

  const isStep2Complete = () => {
    return fields.firstName && fields.lastName && fields.email && fields.phoneNumber && fields.address;
  };

  // Function to generate progress step class with checkmarks or numbers
  const renderStepStatus = (stepNumber) => {
    const checkmarkSVG = (
      <svg
        className={view['step-checkmark']}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <polyline points="20 6 9 17 4 12" />
      </svg>
    );
  
      if (stepNumber === 1) {
    return currentStep > 1 ? checkmarkSVG : <span className={view['step-number']}>{stepNumber}</span>;
  }

  if (stepNumber === 2) {
    return isStep2Complete() ? checkmarkSVG : <span className={view['step-number']}>{stepNumber}</span>;
  }

  return <span className={view['step-number']}>{stepNumber}</span>;

  };  
    
  const pushToDataLayer = useDataLayer()

  const requiredFields = ["VIN", "StockNumber", "Year", "Make", "Model", "Trim","IsNew"]
  const renderizable = useRenderizable({ finalPrice, vehicleInfo }, requiredFields)
  const isRenderizable = props.contextualized ? renderizable : true
  
  const dealerID = vehicleInfo.DealerID ? vehicleInfo.DealerID : ''
  const dealerName = data.siteData.siteData.siteMetadata.dealers[0].DealerName !== '' ? data.siteData.siteData.siteMetadata.dealers[0].DealerName : '';
  const filterLabels = () => Object.fromEntries(
    requiredFields.map(element => [element, vehicleInfo[element]])
  )

  const [fields, setFields] = useState({
    enquiryType: '', firstName: null, lastName: null, email: null, phoneNumber: null, contact_preference: "phone", comments: null,
    siteId:siteID, pageSource: props.pageSource, pageURL: props.pageURL, notes: '', button: '', formType: ''
    //,extraFields: [{name: 'Sample-String', type: "text", value: ''}, {name: 'Sample-Number', type: "number", value: ''}]
  })

  const location = useLocation()
  useEffect(() => {
    if(props.autofillable){
      const userForm = {}
      const params = new URLSearchParams(location.search)
      for (const [key, value] of params) {
        fields.hasOwnProperty(key) && (userForm[key] = value);
      }
      setFields({ ...fields, ...userForm})
    }
  }, [])

  const [error, setError] = useState([])

  const [statusMsg, setStatusMsg] = useState("")
  const [statusNote, setStatusNote] = useState("")
  const [loading, isLoading] = useState(false)
  const [success, setSuccess] = useState(false )
  const [engagement, setEngagement] = useState(false)

  useEffect(() => {
    if (engagement) {
      captureEvent({
        event: 'asc_form_engagement',
        comm_type: 'form',
        comm_status: 'start',
        element_text: engagement?.innerText,
        department: 'sales',
        form_name: 'Enquiry',
        form_type: 'sales',
        enquiry_type: fields.enquiryType,
        item_id:  vehicleInfo.VIN,
        item_number: `${vehicleInfo.Year} ${vehicleInfo.Make} ${vehicleInfo.Model} ${vehicleInfo.Trim}`,
        item_price: finalPrice > 0 ? `${finalPrice}` : undefined,
        item_condition: `${vehicleInfo.IsNew ? 'New' : 'Used'}`,
        item_year: vehicleInfo.Year,
        item_make: vehicleInfo.Make,
        item_model: vehicleInfo.Model,
        item_variant: vehicleInfo.Trim,
        item_color: vehicleInfo.ExteriorColor,
        item_type:'vehicle',
        item_category: vehicleInfo.BodyType,
        item_fuel_type: vehicleInfo.EngineFuelType,
        item_inventory_date: vehicleInfo.InStockDate,
      }, {
        handlers: ['ASC']
      }).then(res => console.log(res)).catch(err => console.log(err))
    }
  }, [engagement])

  const handleTextInput = (name, value) => { 
    const isErrored = error.indexOf(name)
    if(isErrored > -1){
      const _error = [...error]
      _error.splice(isErrored, 1)
      setError(_error)
    }

    setStatusMsg("")
    setFields({ ...fields, ...{ [name]: value } })
  }

  const handleCustomInput = (name, newValue, idx) => { 
    setStatusMsg("")
    const arrayName = 'extraFields'
    const replaceValue = fields.extraFields.map((field, i) => {
      if(i !== idx) {
        return field
      }
      else {
        return {
          name: field.name,
          type: field.type,
          value: newValue
        };
      }
    })
    setFields({...fields, ...{[arrayName]: replaceValue}})
  }

  const phoneMask = (e) => {
    var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '')
  }

  const submitForm = async (e) => {
    e.preventDefault()

    setFields(prevFields => ({
      ...prevFields,
      comments: `${prevFields.comments || ''}\nInquiry Type: ${prevFields.enquiryType}`  // Append enquiryType to comments
    }));
  
    const updatedFields = { ...fields, vehicle: filterLabels(), dealerID }; 
    
    const missing = mfc.validateForm(fields, ['enquiryType', 'firstName', 'lastName', 'email', 'phoneNumber', 'contact_preference'])
    if (missing.length > 0) {
      setError([...missing])
      return setStatusMsg("One or more fields are missing!")
    }

    isLoading(true)

    const body = { ...fields, vehicle: filterLabels(), vehiclePrice, dealerID }
    const result = await mfc.sendForm(leadURL, body)
    if (result.hasOwnProperty("error")) {
      setStatusMsg("Uh oh! Something went wrong, please try again!")
    } else {
      captureEvent({
        event: 'asc_form_submission',
        comm_type: 'form',
        comm_outcome: 'crm_update',
        submission_id: `${result.result.data.LeadID.id}`,
        element_text: 'submit',
        department: 'sales',
        form_name: 'Enquiry',
        form_type: 'sales',
        item_id:  vehicleInfo.VIN,
        item_number: `${vehicleInfo.Year} ${vehicleInfo.Make} ${vehicleInfo.Model} ${vehicleInfo.Trim}`,
        item_price: finalPrice > 0 ? `${finalPrice}` : undefined,
        item_condition: `${vehicleInfo.IsNew ? 'New' : 'Used'}`,
        item_year: vehicleInfo.Year,
        item_make: vehicleInfo.Make,
        item_model: vehicleInfo.Model,
        item_variant: vehicleInfo.Trim,
        item_color: vehicleInfo.ExteriorColor,
        item_type:'vehicle',
        item_category: vehicleInfo.BodyType,
        item_fuel_type: vehicleInfo.FuelType,
        item_inventory_date: vehicleInfo.InStockDate,
      }, {
        handlers: ['ASC']
      }).then(res => console.log(res)).catch(err => console.log(err))
      setSuccess(true)
      pushToDataLayer("form-submitted", result)
      pushToDataLayer("contact", result)
      setStatusMsg("Success! The message has been sent!")
      setStatusNote(fields.notes)
    }
    isLoading(false)

  }
  const setCurrentStepWithCommentClear = (step) => {
    if (step === 1) {
      // Clear the comments field when navigating back to step 1
      setFields(prevFields => ({
        ...prevFields,
        comments: ''  
      }));
      setEnquiryType('');
    }
    setCurrentStep(step);
  };
  
  return (
    isRenderizable && (
      <div className={`contact-formCont ${view["contact-form"]}`}>
        {/* Header Section */}
        <div className={view.enquiryHeader}>
          <div className={view['enquiryVehicleMobile']}>
            {isModelPage ? (
              <>
                <div className={view["amb103MobileVehicleText"]}>
                  <h3>{modelSubtitle || fallbackSubtitle}</h3> 
                  <h1>{modelTitle || fallbackTitle}</h1> 
                </div>
                <img src={jellybeanImage} alt="Aston Martin Vehicles" className={view["vehicle-photo"]} />
              </>
            ) : (
              context?.mainPhotoUrl ? (
                <>
                  <div className={view["amb103MobileVehicleText"]}>
                    <h3>{dealerName}</h3> 
                    <h1>{vehicleInfo.Model}</h1> 
                  </div>
                  <img 
                    src={context.mainPhotoUrl} 
                    alt={vehicleInfo?.Model || "Vehicle"} 
                    className={view["vehicle-photo"]} 
                  />
                </>
              ) : (
                <>
                <img 
                  src={jellybeanImage}  // fallback image in case no mainPhotoUrl is available
                  alt="Aston Martin Vehicles" 
                  className={view["vehicle-photo"]} 
                />
                <h2>{dealerName}</h2>
                </>
              )
            )}
          </div>
          <h3>Make an Inquiry</h3>
          <h5>{enquiryType ? enquiryType : 'Select an inquiry type'}</h5>
          <div className={view.enquiryProgress}>
            <ul>
              <li 
                onClick={() => currentStep > 1 && setCurrentStepWithCommentClear(1)}
                className={currentStep > 1 ? view['passed-step'] : ''}
              >
                {renderStepStatus(1)} Nature of inquiry
              </li>
              <li 
                onClick={() => currentStep > 2 && setCurrentStep(2)}
                className={isStep2Complete() ? view['passed-step'] : ''}
              >
                {renderStepStatus(2)} Contact details
              </li>
            </ul>
          </div>
        </div>
        <div className={view['enquiryCont']}>
        <div className={view['enquiryPersonalDetails']}>
        {/* Step 1: Inquiry Type Selection */}
        {currentStep === 1 && (
          <div className={view['enquiry-selection']}>
            <p>Tell us about the nature of your inquiry below</p>

            <button 
              className={view['reasonButton']} 
              ref={saleButtonRef}  // Attach ref
              onClick={() => {
                setEngagement({ innerText: "Inquiry Type: Sale/purchase" });
                handleEnquiryTypeSelection('Sale/purchase', saleButtonRef);  // Pass ref to handler
              }}
            >
              Sale/purchase<span className={view['amb103Reason']}>Sale</span>
            </button>

            <button 
              className={view['reasonButton']} 
              ref={testDriveButtonRef}  // Attach ref
              onClick={() => {
                setEngagement({ innerText: "Inquiry Type: Book a test drive" });
                handleEnquiryTypeSelection('Book a test drive', testDriveButtonRef);  // Pass ref to handler
              }}
            >
              Book a test drive<span className={view['amb103Reason']}>Book</span>
            </button>

            <button 
              className={view['reasonButton']} 
              ref={generalButtonRef}  // Attach ref
              onClick={() => {
                setEngagement({ innerText: "Inquiry Type: General" });
                handleEnquiryTypeSelection('General', generalButtonRef);  // Pass ref to handler
              }}
            >
              General<span className={view['amb103Reason']}>Gene</span>
            </button>

            {/* "Next" button to proceed to the next step */}
            <div className={view['amb103Next']}>
              <button
                onClick={() => enquiryType && setCurrentStep(2)}  // Only move to step 2 if enquiryType is set
                disabled={!enquiryType}  // Disable the button if no inquiry type is selected
                className={!enquiryType ? view['disabledButton'] : ''} // Optional styling for disabled state
              >
                <p>Next</p><img src={nextArrow} alt="Next" className={view['nextArrow']} /><img src={nextArrowHover} alt="Next" className={view['nextArrowHover']} />
              </button>
            </div>
          </div>
        )}
  
        {/* Step 2: Contact Details */}
        {currentStep === 2 && (
          <div className={view['contact-details-step']}>
            <h5 className={view['contact-details-instruction']}>Please provide us with your personal details and contact information</h5>
            {/* Title Input */}
            <div className={`contact-inputCont ${view["col-md-12"]} ${view["amb103Title"]}`} onClick={() => setEngagement({ innerText: "Title" })}>
              <label htmlFor="title">Title</label>
              <div className={view["title-options"]}>
                <label>
                  <input type="radio" name="title" value="Mr" 
                    checked={fields.title === "Mr"}
                    onChange={(e) => handleTextInput(e.target.name, e.target.value)} />
                  Mr
                </label>
                <label>
                  <input type="radio" name="title" value="Mrs" 
                    checked={fields.title === "Mrs"}
                    onChange={(e) => handleTextInput(e.target.name, e.target.value)} />
                  Mrs
                </label>
                <label>
                  <input type="radio" name="title" value="Miss" 
                    checked={fields.title === "Miss"}
                    onChange={(e) => handleTextInput(e.target.name, e.target.value)} />
                  Miss
                </label>
                <label>
                  <input type="radio" name="title" value="Ms" 
                    checked={fields.title === "Ms"}
                    onChange={(e) => handleTextInput(e.target.name, e.target.value)} />
                  Ms
                </label>
              </div>
            </div>
            {/* First Name Input */}
            <div className={`contact-inputCont ${view["col-md-6"]} ${view["amb103FirstName"]}`} onClick={() => setEngagement({ innerText: "First Name" })}>
              <label htmlFor="firstName">First Name</label>
              <input className="contact-input" id="firstName" name="firstName"
                value={fields.firstName} placeholder=""
                style={{ border: error.indexOf("firstName") > -1 && "1px solid red" }}
                onChange={(e) => handleTextInput(e.target.name, e.target.value)} />
            </div>
  
            {/* Last Name Input */}
            <div className={`contact-inputCont ${view["col-md-6"]}`} onClick={() => setEngagement({ innerText: "Last Name" })}>
              <label htmlFor="lastName">Last Name</label>
              <input className="contact-input" id="lastName" name="lastName"
                value={fields.lastName} placeholder=""
                style={{ border: error.indexOf("lastName") > -1 && "1px solid red" }}
                onChange={(e) => handleTextInput(e.target.name, e.target.value)} />
            </div>
  
            {/* Phone Number Input */}
            <div className={`contact-inputCont contact-phone ${view["col-md-12"]}`} onClick={() => setEngagement({ innerText: "Phone" })}>
              <label htmlFor="phoneNumber">Phone number</label>
              <input className="contact-input" type="tel" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                id="phoneNumber" name="phoneNumber"
                value={fields.phoneNumber} placeholder="(555) 555-5555"
                style={{ border: error.indexOf("phoneNumber") > -1 && "1px solid red" }}
                onChange={(e) => handleTextInput(e.target.name, e.target.value)}
                onInput={phoneMask}
              />
            </div>
  
            {/* Email Input */}
            <div className={`contact-inputCont ${view["col-md-12"]}`} onClick={() => setEngagement({ innerText: "Email" })}>
              <label htmlFor="email">Email</label>
              <input className="contact-input" type="email" id="email" name="email"
                value={fields.email} placeholder=""
                style={{ border: error.indexOf("email") > -1 && "1px solid red" }}
                onChange={(e) => handleTextInput(e.target.name, e.target.value)} />
            </div>
  
            {/* Address Input */}
            <div className={`contact-inputCont ${view["col-md-12"]}`} onClick={() => setEngagement({ innerText: "Address" })}>
              <label htmlFor="address">Address</label>
              <input className="contact-input" type="text" id="address" name="address"
                value={fields.address} placeholder=""
                style={{ border: error.indexOf("address") > -1 && "1px solid red" }}
                onChange={(e) => handleTextInput(e.target.name, e.target.value)} />
            </div>

            {/* Contact Preference */}
            {/* <div className={`contact-inputCont contact_preference_cont ${view.amb103ContactPref}`} onClick={() => setEngagement({ innerText: "Contact Type" })}>
              <label htmlFor="contact_preference">How should we contact you?</label>
              <select className="contact-input" name="contact_preference" id="contact_preference"
                value={fields.contact_preference}
                style={{ border: error.indexOf("contact_preference") > -1 && "1px solid red" }}
                onChange={(e) => handleTextInput(e.target.name, e.target.value)} >
                <option value="phone">Phone Call</option>
                <option value="text">Text</option>
                <option value="email">Email</option>
              </select>
            </div> */}
  
            {/* Comments */}
            <div className="contact-inputCont contact-comment" onClick={() => setEngagement({ innerText: "Comment" })}>
              <label htmlFor="comments">Your inquiry</label>
              <textarea className="contact-input" id="comments" name="comments"
                value={fields.comments} placeholder=""
                onChange={(e) => handleTextInput(e.target.name, e.target.value)} />
            </div>
  
            {/* Extra Fields */}
            {fields.extraFields && fields.extraFields.length > 0 && (
              <div className="extraFields">
                {fields.extraFields.map((field, idx) => (
                  <div key={field.name}>
                    <label htmlFor={field.name}>{field.name.replace(/-/g, ' ')}</label>
                    <input name={field.name} id={field.name} value={field.value} type={field.type} onChange={(e) => handleCustomInput(e.target.name, e.target.value, idx)} />
                  </div>
                ))}
              </div>
            )}
                    {/* Status and Submit Section */}
        <div className={`contact-inputCont contact-border ${view["amb103Bottom"]}`}>
          <div className={`contact-response ${statusMsg.length && "contact-failure"}`}>
            {statusMsg}
            <p className="contact-status-note">{statusNote}</p>
          </div>
  
          <div className={`contact-disclaimer ${view["amb103Disclaimer"]}`} dangerouslySetInnerHTML={{ __html: disclaimerContact }}></div>
  
          {!success && !loading ? (
            <button className="contact-button" onClick={submitForm}>SUBMIT</button>
          ) : (
            <div className={success ? "circle-loader-contact load-complete-contact" : "circle-loader-contact"}>
              <div className={success ? "checkmark-contact draw" : ""}></div>
            </div>
          )}
        </div>

          </div>
        )}
  
        </div>{/* enquiryPersonalDetails */}
        <div className={view['enquiryVehicle']}>
          {isModelPage || isRangePage ? (
            <>
              <h3>{modelSubtitle || fallbackSubtitle}</h3> 
              <h1>{modelTitle || fallbackTitle}</h1> 
              <img src={jellybeanImage} alt="Aston Martin Vehicles" className={view["vehicle-photo"]} />
            </>
          ) : (
            context?.mainPhotoUrl ? (
              <img 
                src={context.mainPhotoUrl} 
                alt={vehicleInfo?.Model || "Vehicle"} 
                className={view["vehicle-photo"]} 
              />
            ) : (
              <>
              <img 
                src={jellybeanImage}  // fallback image in case no mainPhotoUrl is available
                alt="Aston Martin Vehicles" 
                className={view["vehicle-photo"]} 
              />
              <h2>{dealerName}</h2>
              </>
            )
          )}
        </div>
        </div> {/* enquiryCont */}
      </div>
    )
  );  
}

EnquiryModal.defaultProps = {
  leadURL: "",
  disclaimerContact: "<p></p>",
  finalPrice: 0,
  vehicleInfo: {},
  isModelPage: false,
  isRangePage: false
}

export default EnquiryModal;